import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchJobDetail } from '../../useFetch'

const JobDetailPage = () => {
  const { titleAndId } = useParams()
  const id = titleAndId.substring(titleAndId.lastIndexOf('-') + 1)
  const { loading, jobDetail, isError } = useFetchJobDetail(
    '/careers-api/v1/job/' + id
  )

  useEffect(() => {
    document.title = jobDetail
      ? jobDetail.title + ': ' + jobDetail.location
      : ''
    window.scrollTo(0, 0)
  }, [jobDetail])

  const {
    title,
    description,
    location,
    essentialFunctions,
    qualifications,
    employmentType,
    jobDetailLink,
  } = jobDetail

  return (
    <>
      <section id='main'>
        <div id='mainContainer' className='container'>
          <div id='mainRow' className='row'>
            <div id='centerCol' className='col-lg-12 col-md-12 col-sm-12'>
              <section id='content'>
                <article>
                  <div className='navClearingExtended'></div>
                  <div
                    className='description'
                    itemscope
                    itemtype='https://schema.org/JobPosting'
                  >
                    {loading && (
                      <div style={{ textAlign: 'center' }}>
                        <img alt='Loading' src='/img/loader.gif' />
                      </div>
                    )}
                    {isError && <div>Error...</div>}

                    <h1 itemprop='title'>{title}</h1>

                    <br />

                    <a
                      className='btn btn-primary'
                      href={jobDetailLink}
                      role='button'
                    >
                      Apply now
                    </a>

                    <br />
                    <br />

                    <h2
                      style={{ fontSize: '1.5rem' }}
                      className='heading-serif'
                    >
                      Location
                    </h2>
                    <div className='heading-serif'>{location}</div>

                    <br />

                    <h2
                      style={{ fontSize: '1.5rem' }}
                      className='heading-serif'
                    >
                      Classification
                    </h2>
                    <div itemprop='employmentType' className='heading-serif'>
                      {employmentType}
                    </div>

                    <br />

                    <h2
                      style={{ fontSize: '1.5rem' }}
                      className='heading-serif'
                    >
                      Job Summary
                    </h2>
                    <div
                      itemprop='description'
                      className='heading-serif'
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></div>

                    <br />

                    <h2
                      style={{ fontSize: '1.5rem' }}
                      className='heading-serif'
                    >
                      What You Do
                    </h2>
                    <div
                      itemprop='responsibilities'
                      className='heading-serif'
                      dangerouslySetInnerHTML={{ __html: essentialFunctions }}
                    ></div>

                    <br />

                    <h2
                      style={{ fontSize: '1.5rem' }}
                      className='heading-serif'
                    >
                      Knowledge & Experience
                    </h2>
                    <div
                      itemprop='qualifications'
                      className='heading-serif'
                      dangerouslySetInnerHTML={{ __html: qualifications }}
                    ></div>

                    <br />

                    <a
                      className='btn btn-primary'
                      href={jobDetailLink}
                      role='button'
                    >
                      Apply now
                    </a>

                    <br />
                    <br />

                    <a
                      itemprop='url'
                      className='btn btn-secondary button-green button-wide'
                      href={
                        'mailto:?subject=Check out this job post from Paper Source&body=' +
                        window.location.href
                      }
                    >
                      Send to yourself or a friend
                    </a>
                  </div>
                </article>
                <br />
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobDetailPage
